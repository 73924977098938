import React from "react"

import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import ContentAbout from "../components/content-about"
import data from "../data/about-hotel.yaml"

const AboutPage = props => {
  const {
    pageContext: { lang },
  } = props
  return (
    <>
      <SEO title={data.title[lang]} />
      <PageHeader
        headerImage={data.headerImage}
        headerAlt={data.headerAlt}
        title={data.title[lang]}
        subTitle={data.subtitle}
        info={data.info[lang]}
      />
      <ContentAbout data={data} />
    </>
  )
}

export default AboutPage
