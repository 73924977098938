import React from "react"
import styled from "styled-components"
import Markdown from "markdown-to-jsx"

import theme from "../components/theme"

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: ${props => props.direction || `column`};

  p:first-child {
    font-family: ${theme.font.header};
    font-size: 1.5rem;
    text-shadow: 0 5px 0.5rem rgba(202, 128, 17, 0.15);

    strong,
    em {
      color: ${theme.colors.info};
    }
  }
  ${theme.breakpoint.mdOnly}{
    height:500px;
  }
`
const Symbol = styled.div`
  text-align: center;
  margin: 1.5rem 0;
  img {
    max-width: 80px;
    max-height: 80px;
    width: 80px;
    height: auto;
    object-fit: contain;
    filter: drop-shadow(0 2px 1rem rgba(202, 128, 17, 0.1));
  }
`
export default function ContentFullWidthSymbol(props) {
  return (
    <Section>
      <Symbol>
        <img src={`/images/${props.symbol}`} alt={props.alt} />
      </Symbol>
      <Markdown>{props.content}</Markdown>
    </Section>
  )
}
