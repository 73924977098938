import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import Markdown from "markdown-to-jsx"

import { LocaleContext } from "./layout"
import ContentFullWidthSymbol from "../components/content-fullwidth-symbol"
import theme from "./theme"

const Section = styled.section`
  padding: 2rem 0;
  ${theme.breakpoint.md} {
    padding: 3rem 0;
  }

  .lll {
    p:first-child {
      font-family: ${theme.font.header};
      font-size: 1.5rem;
      text-shadow: 0 5px 0.5rem rgba(202, 128, 17, 0.15);
    }
    strong,
    em {
      color: ${theme.colors.info};
    }
  }
`
const Image = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  box-shadow: ${theme.shadow.main};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${theme.breakpoint.mdOnly} {
    width: 100%;
    height: 500px;
  }
  ${theme.breakpoint.lg} {
    width: 400px;
    max-width: 100%;
    height: 500px;
  }
`
export default function ContentAbout(props) {
  const {
    pageContext: { lang },
  } = React.useContext(LocaleContext)

  return (
    <>
      <Section>
        <Container>
          <Row>
            <Col md="5" className="ml-auto d-flex align-items-center">
              <ContentFullWidthSymbol
                content={props.data.content[lang].story}
                symbol={props.data.contentLogo}
              />
            </Col>
            <Col md="6" lg="5" className="mr-auto">
              <Image>
                <img src={`/images/${props.data.storyimage[0]}`} alt="" />
              </Image>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col md="6">
              <Image className="ml-auto mb-5">
                <img src={`/Images/${props.data.tripleLImage}`} alt="" />
              </Image>
            </Col>
            <Col
              md="5"
              lg="4"
              className="mr-auto d-flex justify-content-center flex-column lll"
            >
              <Markdown>{props.data.content[lang].tripleLText}</Markdown>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}
